import React from 'react';
import _ from 'lodash';

import colors from '../config/color';
import CircleNumber from './circleNumber';

const CodeInput = ({ user }) => (
  <>
    <div className="success-content">
      <h1>序號使用完畢</h1>
      <div className="text-content">
        <p>請下載 HereHear 聽聽 App</p>
        <a href="https://play.google.com/store/apps/details?id=com.Bamboodd.HereHearV2&hl=zh_TW">
          <img src="/images/google@3x.png"></img>
        </a>
      </div>
    </div>
    <style jsx>{`
      .success-content {
        margin: 20px 16px 50px 16px;
        min-width: 343px;
      }

      h1 {
        color: ${colors['black-p']};
        font-family: NotoSansCJKtc-Medium;
        font-size: 28px;
        line-height: 1.33;
        margin: 32px 0 32px 0px;
        text-align: center;
        font-weight: 500;
      }

      .text-content {
        margin: 0 20px 20px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      p {
        color: ${colors['black-p']};
        font-family: NotoSansCJKtc-Regular;
        font-size: 16px;
        line-height: 1.5;
        margin-left: 8px;
      }

      img {
        margin: 8px 6px;
        width: 114px;
        height: 34px;
      }

      @media (min-width: 768px) {
        .content {
          display: flex;
          justify-content: center;
        }

        .success-content {
          margin: 20px 60px 50px 60px;
        }

        hr {
          margin: 20px 0 0 0;
        }
    `}</style>
  </>
);

export default CodeInput;
