import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import colors from '../config/color';

const CircleNumber = props => {
  const { text, btnStyle } = props;
  return (
    <button style={btnStyle} className="number-content">
      <div>{text}</div>

      <style jsx>{`
        .number-content {
          color: ${colors['white']};
          background-color: ${colors['bright-purple']};
          font-family: NotoSansCJKtc-Regular;
          font-size: 10px;
          border-radius: 100px;
          padding: 4px 9px;
          display: inline-flex;
          
        }

        @media (min-width: 768px) {
          .button-content {
            font-size: 24px;
            line-height: 36px;
            padding: 16px 40px;
        }

        @media (min-width: 1200px) {
          }
      `}</style>
    </button>
  );
};

CircleNumber.propTypes = {
  text: PropTypes.string,
  btnStyle: PropTypes.object,
};

CircleNumber.defaultProps = {
  text: '',
  btnStyle: {},
};

export default CircleNumber;
