import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';

import SEO from '../../../../components/seo';
import Layout from '../../../../components/layout';
import IOSCouponSuccess from '../../../../components/partnerCouponSuccess.ios';
import AndroidCouponSuccess from '../../../../components/partnerCouponSuccess.android';

const Success = ({ location }) => {
  let iOS = false;
  if (typeof window !== 'undefined') {
    iOS =
      /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream;
  }

  return (
    <Layout
      showHamburgerBtn={false}
      showAccountBtn={false}
      showHeader={true}
      contentStyle={{
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: `url("/images/background.jpg")`,
      }}
    >
      {/* TODO: SEO */}
      <SEO title="Here Hear 聽聽" />
      <hr />
      <div className="content">
        {iOS ? (
          <IOSCouponSuccess location={location} />
        ) : (
          <AndroidCouponSuccess location={location} />
        )}
      </div>

      <style jsx>{`
        .content {
          display: flex;
          justify-content: center;
          align-content: center;
        }
        @media (min-width: 768px) {
          .content {
            display: flex;
            justify-content: center;
          }
        }
      `}</style>
    </Layout>
  );
};

export default Success;
